@use "./colors" as *;
@use "./typhography" as *;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: $bg-white;
  font-family: $titles-font;
  position: relative;
}

.container {
  max-width: 133rem !important;
  margin: 0 auto !important;
  padding: 0 2.5rem !important;
}

// scroll bar ------

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2a43cf #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #2a43cf;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #2a43cf;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #ff2525;
}
